/// <reference path="../../typings/index.d.ts" />
/// <reference path="../../tools/typings/typescriptDemo.d.ts" />

module scriptsure.components {

	export class DrugContentDirective implements angular.IDirective {

		static $inject:string[] = [
			'$compile'
		];

		public restrict:string = 'A';
		public replace:boolean = false;

		constructor(private $compile:angular.ICompileService) {
		}

		public link =
			($scope:angular.IScope,
			 elm:any,
			 attrs:IDrugContentAttributes,
			 ngModel:angular.INgModelController):void => {
				$scope.$watch(attrs.drugContent, (htmlContent:string):void => {
					var template:any = this.$compile(htmlContent)($scope);
					elm.html(template);
				});
			};

		static factory(): angular.IDirectiveFactory {
			const directive = ($compile:angular.ICompileService) => new DrugContentDirective($compile);
			//directive.$inject = ['$compile'];
			return directive;
		}
	}

	angular.module('scriptsure.components').directive('drugContent', DrugContentDirective.factory());

	export interface IDrugContentAttributes extends angular.IAttributes {
		drugContent: string;
	}
}